import { useState, useEffect } from 'react';
import { getCalApi } from '@calcom/embed-react';
import { useRouter, useSettings } from '@backpackjs/storefront';
import { sendGTMEvent } from '@next/third-parties/google';
import ReCAPTCHA from 'react-google-recaptcha';

import { Schema } from './FeatureHalfHero.schema';
import { Icons } from '../../snippets/Icons';

import { Section } from '../../snippets';

export function FeatureHalfHero({ cms }) {
  const router = useRouter();
  const {
    heading,
    description,
    badgeLabel,
    icon,
    features,
    formId,
    formRedirect,
    buttonLabel,
    image,
    imageDesktop,
    imageAlt,
    scheduleLink,
    includeEmail,
  } = cms;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal('ui', {
        theme: 'dark',
        styles: {
          branding: { brandColor: '#000000' },
        },
      });

      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          // `data` is properties for the event.
          // `type` is the name of the action
          // `namespace` is the Cal namespace for which the event is fired

          const { data } = e.detail;

          sendGTMEvent({
            event: 'calendar_booking_successful',
            bookingId: data?.booking?.uid,
          });
        },
      });
    })();
  }, []);

  function handleChange(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading || !email) return;

    if (!captchaValue) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    setLoading(true);
    try {
      const resp = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          formId,
        }),
      });
      const respJson = await resp.json();
      if (!respJson.success) throw new Error('Could not subscribe');
      setSuccess(true);
      if (formRedirect) {
        try {
          const redirectUrl = new URL(formRedirect);
          if (includeEmail) redirectUrl.searchParams.append('email', email);
          window.location = redirectUrl.href;
        } catch (err) {
          router.push(formRedirect);
        }
      }
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Section cms={cms}>
      <div className="northern-lights-gradient pt-6">
        <div className="lg:grid lg:grid-cols-2 lg:items-center">
          <div className="flex flex-col items-center px-4 md:mx-auto md:max-w-2xl md:items-start">
            {badgeLabel ? (
              <div className="badge-wrapper mb-6">
                {icon ? <Icons icon={icon} className="badge-icon" /> : null}
                <span className="badge-label">{badgeLabel}</span>
              </div>
            ) : null}
            {heading ? (
              <h1 className="h2 mb-6 text-center selection:bg-brand md:text-left">
                {heading}
              </h1>
            ) : null}
            {description ? (
              <p className="mb-6 text-center selection:bg-brand selection:text-white md:text-left md:text-white-600">
                {description}
              </p>
            ) : null}
            {features?.length > 0 && (
              <ul className="flex items-center justify-center gap-6 md:justify-start">
                {features.map((feature) => {
                  return (
                    <li className="flex items-center gap-2 text-sm font-medium">
                      <span className="flex h-[18px] w-[18px] shrink-0 items-center justify-center rounded-full bg-brand text-black">
                        <Icons icon="checkmark" />
                      </span>
                      {feature.label}
                    </li>
                  );
                })}
              </ul>
            )}

            {scheduleLink?.calId ? (
              <div className="mx-auto my-8 flex w-full flex-col justify-center gap-3 md:mx-0 md:flex-row md:justify-start md:px-0">
                <button
                  type="button"
                  data-cal-link={scheduleLink?.calId}
                  data-cal-config='{"theme":"dark"}'
                  className="btn-primary w-full"
                >
                  <Icons icon="clock" />
                  {scheduleLink?.buttonLabel}
                </button>
              </div>
            ) : formId ? (
              <form
                className="relative mx-auto my-8 w-full md:mx-0 md:px-0"
                onSubmit={handleSubmit}
              >
                <div className="flex w-full flex-col justify-center gap-3 md:flex-row md:justify-start">
                  <input
                    type="email"
                    className="input-email-white md:max-w-[320px]"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleChange}
                    required
                  />

                  <button
                    type="submit"
                    className="btn-primary"
                    disabled={loading || !captchaValue}
                  >
                    {loading ? 'Loading...' : success ? 'Sent!' : buttonLabel}
                  </button>
                </div>

                {/* Google reCAPTCHA Widget */}
                {email && (
                  <div className="absolute top-full pt-2">
                    <ReCAPTCHA
                      sitekey="6Ld32v8qAAAAAEgfFdTBJbfu4-YUuh5QUNRBeUoB" // Replace with your site key
                      onChange={(value) => setCaptchaValue(value)}
                    />
                  </div>
                )}
              </form>
            ) : null}
          </div>
          {(imageDesktop || image) && (
            <div className="mb-8 pl-4 md:mb-0 lg:relative lg:right-[-50px] lg:pl-0">
              <picture className="w-full">
                {imageDesktop && image && (
                  <source
                    srcSet={imageDesktop.src}
                    media="(min-width: 768px)"
                  />
                )}
                <img
                  className="w-full"
                  src={image?.src || imageDesktop?.src}
                  alt={imageAlt}
                />
              </picture>
            </div>
          )}
        </div>
        <div className="py-1 md:py-12" />

        {/* {settings?.logos?.logoMarquee?.length > 0 && (
        <div className="py-12 md:py-[144px]">
          <MarqueeComponent logoMarquee={settings.logos.logoMarquee} />
        </div>
      )} */}
      </div>
    </Section>
  );
}

FeatureHalfHero.displayName = 'FeatureHalfHero';
FeatureHalfHero.Schema = Schema;
