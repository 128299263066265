import { useState } from 'react';
import { useRouter } from '@backpackjs/storefront';
import ReCAPTCHA from 'react-google-recaptcha';

import { Schema } from './FeatureEmailSignup.schema';

import { Section } from '../../snippets';

export function FeatureEmailSignup({ cms }) {
  const [captchaValue, setCaptchaValue] = useState(null);
  const router = useRouter();
  const {
    formId,
    formRedirect,
    heading,
    subheading,
    buttonLabel,
    imageAlt,
    imageMobile,
    imageDesktop,
  } = cms;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleChange(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading || !email) return;

    if (!captchaValue) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    setLoading(true);
    try {
      const resp = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          formId,
        }),
      });
      const respJson = await resp.json();
      if (!respJson.success) throw new Error('Could not subscribe');
      setSuccess(true);
      if (formRedirect) {
        try {
          const redirectUrl = new URL(formRedirect);
          window.location = redirectUrl.href;
        } catch (err) {
          router.push(formRedirect);
        }
      }
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Section cms={cms}>
      <div
        className="flex flex-col gap-14 px-0 pt-16 md:px-20 md:pt-[100px]"
        style={{
          background:
            'radial-gradient(159.97% 99.97% at 49.96% 0%, rgba(0, 0, 0, 0) 37.41%, #10A875 80.66%, #FFFFFF 100%, #FFFFFF 100%)',
        }}
      >
        <div className="flex flex-col gap-8 px-4">
          <div className="flex flex-col gap-3 md:gap-5">
            {heading && (
              <h2 className="text-center selection:bg-brand">{heading}</h2>
            )}
            {subheading && (
              <p className="sub-heading whitespace-pre-line text-center selection:bg-brand md:whitespace-normal">
                {subheading}
              </p>
            )}
          </div>
          {formId ? (
            <form
              className="relative flex w-full max-w-lg flex-col gap-4 self-center md:flex-row"
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                className="input-email"
                placeholder="Enter your email"
                value={email}
                onChange={handleChange}
                required
              />
              <button
                type="submit"
                className="btn-primary shrink-0"
                disabled={loading || !captchaValue}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {loading ? 'Loading...' : success ? 'Sent!' : buttonLabel}
              </button>

              {/* Google reCAPTCHA Widget */}
              {email && (
                <div className="absolute top-full pt-2">
                  <ReCAPTCHA
                    sitekey="6Ld32v8qAAAAAEgfFdTBJbfu4-YUuh5QUNRBeUoB" // Replace with your site key
                    onChange={(value) => setCaptchaValue(value)}
                  />
                </div>
              )}
            </form>
          ) : null}
        </div>
        {(imageDesktop || imageMobile) && (
          <div className="flex justify-center md:order-3">
            <picture className="w-full">
              {imageDesktop && imageMobile && (
                <source srcSet={imageDesktop.src} media="(min-width: 768px)" />
              )}
              <img
                className="w-full"
                src={imageMobile?.src || imageDesktop?.src}
                alt={imageAlt}
              />
            </picture>
          </div>
        )}
      </div>
    </Section>
  );
}

FeatureEmailSignup.displayName = 'FeatureEmailSignup';
FeatureEmailSignup.Schema = Schema;
