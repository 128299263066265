import Script from 'next/script';
import { GoogleTagManager } from '@next/third-parties/google';
import Head from 'next/head';
import { Header } from '../snippets/header/header';
import { Footer } from '../snippets/footer';
import { Modal } from '../snippets/modal';
import { Overlay } from '../snippets/overlay';

import { GlobalContextProvider } from '../contexts';

function StorefrontLayout({ ContentForLayout, ...props }) {
  return (
    <GlobalContextProvider>
      <Head>
        <link rel="shortcut icon" href="/icons/favicon.svg" />
      </Head>
      {/* <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-580BP2B4CD"
      /> */}
      {/* <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-580BP2B4CD');
        `}
      </Script> */}

      {/* <Script>
        {`
          !function () {var reb2b = window.reb2b = window.reb2b || [];
          if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];
          reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);
          args.unshift(method);reb2b.push(args);return reb2b;};};
          for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}
          reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;
          script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/EN4M0HKGM7OM.js.gz";
          var first = document.getElementsByTagName("script")[0];
          first.parentNode.insertBefore(script, first);};
          reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("EN4M0HKGM7OM");}();
        `}
      </Script> */}

      <Script
        async
        defer
        type="text/javascript"
        id="hs-script-loader"
        src="//js.hs-scripts.com/22338248.js"
      />

      <Script
        defer
        id="leadpipe-script-loader"
        src="https://api.leadpipe.com/cs?apiKey=11320327-e747-49e3-9ed4-1c3fc0dc1acb"
      />

      <Script
        defer
        id="warmly-script-loader"
        src="https://opps-widget.getwarmly.com/warmly.js?clientId=9813acb22c9471794c5700cfb732ad82"
      />

      <Script
        defer
        data-domain="packdigital.com"
        src="https://plausible.io/js/script.js"
      />

      <Script src="https://js.hsforms.net/forms/embed/v2.js" />
      {/* <Script src="https://www.google.com/recaptcha/api.js" /> */}

      <GoogleTagManager gtmId="GTM-PKH9VXF" />
      <main>
        <Header />
        <ContentForLayout {...props} />
        <Footer />
        <Modal />
        <Overlay />
      </main>
    </GlobalContextProvider>
  );
}

export default StorefrontLayout;
